<template>
    <div class="device-wrap">
        <Title name='设备合同' :operate="true">
            <el-button type="primary" size="small" @click="goto('/add','add')">添加设备合同</el-button>
        </Title>

        <div class="search">
            <span>项目名称：</span>
            <el-input style="width:20%;margin:0 20px 0 0" v-model="porj_name" size="small" placeholder="请输入内容">
            </el-input>
            <el-button type="primary" size="small" @click="query">查询</el-button>
        </div>
        <div style="padding:0 10px">
            <el-table :data="tableData" style="width: 100%"
                :header-cell-style="{'background':'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px','text-align':'center'}">

                <el-table-column align='center' prop="projName" label="项目名称" width="180"></el-table-column>
                <el-table-column align='center' prop="contractNo" label="合同编号" width="180"></el-table-column>
                <el-table-column align='center' prop="developer" width="200" label="开发商"></el-table-column>
                <el-table-column align='center' prop="constractor" width="200" label="承建商"></el-table-column>
                <el-table-column align='center' prop="sellMode" width="100" label="销售模式"></el-table-column>
                <el-table-column align='center' prop="projType" width="100" label="项目类别"></el-table-column>
                <el-table-column align='center' prop="projLeader" width="180" label="业务负责人"></el-table-column>
                <el-table-column align='center' prop="loginName" width="130" label="项目添加人"></el-table-column>
                <el-table-column fixed="right" width="240" label="操作" align="center">
                    <template slot-scope="scope">
                        <span class="operate" @click='goto("/check","check",scope.row)'>查看</span>
                        <span class="operate" @click='goto("/associateOrUpDate","associate",scope.row)'>关联设备</span>
                        <span class="operate " @click='goto("/associateOrUpDate","update",scope.row)'>修改</span>
                        <span class="operate last" @click='deleted(scope.row)'>删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination">
            <el-pagination background layout="prev, pager, next" :total='total' :page-size='10'
                :current-page.sync="currentPage" @current-change="changeCurrentPage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import Title from '../../component/Title'
    import {
        getEquipmentList,
        delById
    } from '../../api'

    export default {
        mounted() {
            this.getList()
        },
        data() {
            return {
                total: 0,
                currentPage: 1,
                tableData: [],
                porj_name: '',
                projName: '',
            }
        },
        methods: {

            getList(projName = '', pageNum = 1, pageSize = 10) {
                getEquipmentList({
                    projName,
                    pageNum,
                    pageSize
                }).then(e => {
                    if (e.errno == 0) {
                        var change = {
                            SELL: '销售',
                            RENT: '出租',
                            ZZ: '住宅',
                            SY: '商用',
                            XZL: '写字楼',
                            ORTHER: '其他'
                        }
                        this.total = e.data.total
                        this.tableData = e.data.list.map(e => {
                            e.projType = change[e.projType]
                            e.sellMode = change[e.sellMode]
                            return e
                        })
                    }
                })
            },

            query() {
                this.projName = this.porj_name

                this.getList(this.projName)
            },


            changeCurrentPage() {
                this.getList(this.projName, this.currentPage)
            },

            goto(e, type, scope) {
                this.$router.push({
                    path: this.$route.path + e,
                    query: {
                        type: type ? type : '',
                        id: scope ? scope.id : ''
                    }
                })
            },

            // 删除合同
            deleted(scope) {
                var _this = this
                delById({
                    id: scope.id
                }).then(e => {
                    if (e.errno == 0) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        _this.getList()
                        return
                    }
                        this.$message({
                            message: e.errmsg,
                            type: 'error'
                        });
                })
            }
        },
        components: {
            Title
        }
    }
</script>

<style lang="less" scoped>
    .device-wrap {
        background: #fff;

        .search {
            padding: 20px 0 20px 20px;
        }

        .pagination {
            padding: 20px;
            box-sizing: border-box;
            text-align: right;
        }

        .operate {
            color: #0786F1;
            margin-right: 20px;
            cursor: pointer;

            &.last {
                margin-right: 0;
            }
        }
    }
</style>